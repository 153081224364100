:root[theme='dark'] {
    --color-divider: var(--text-color-fade);
    
    --background-color: rgb(15, 23, 42);
    --background-color-primary: rgba(45, 212, 191, 0.1);
    --background-color-fade: rgba(30, 41, 59, 0.5);
    --background-color-header: rgb(40, 42, 54);

    --text-color: rgb(148, 163, 184);
    --text-color-bold: rgb(226, 232, 240);
    // --text-color-primary: rgb(255, 204, 0);
    --text-color-primary: rgb(94, 234, 212);
    --text-color-fade: rgb(100, 116, 139);
    
    --color-img-border: rgba(226, 232, 240, 0.1);
    --color-img-border-hover: rgba(226,232,240,.3);
    --color-indicator: rgb(71 85 105);

    --font-family: "Inter", "Inter_Fallback", ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-bold: "Inter_Bold", "Inter_Fallback", ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-feature-settings: "ss03","cv02","cv11";
}

:root {
    --color-divider: var(--text-color-fade);
    
    --background-color: rgb(250, 238, 231);
    --background-color-primary: rgba(208, 216, 245, .5);
    --background-color-fade: rgba(208, 216, 245, .3); 
    --background-color-header: rgb(249, 223, 209);

    --text-color: rgb(54, 52, 77);
    --text-color-bold: rgb(0, 0, 0);
    // --text-color-primary: rgb(255, 204, 0);
    --text-color-primary: rgb(103, 61, 230);
    // --text-color-primary: #8158fa
    --text-color-fade: rgb(100, 116, 139);
    
    --color-img-border: rgba(168, 181, 196, 0.1);
    --color-img-border-hover: rgba(24, 10, 225, 0.3);
    --color-indicator: rgb(45, 65, 92);
    --color-icon-linkedin-hover: rgb(53, 116, 175);
    --color-icon-github-hover: rgb(103, 71, 199);
    --color-icon-mail-hover: #f4b312;


    --font-family: "Inter", "Inter_Fallback", ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-bold: "Inter_Bold", "Inter_Fallback", ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-feature-settings: "ss03","cv02","cv11";
    
}
